@import "custom-variable";
.login-screen {
    display: flex;
    justify-content: space-between;
     
    
    .cards-box {

        .form-group {
            position: relative;
        }

        .show-password  {
            display: inline-block;
            position: absolute;
            top: 51px;
            right: 0;
            transform: translateX(-50%);

            @media (max-width: 992px) {
                top: 58px;
            }
        }
    }

    .content-info {
        border-top: 1px solid rgba(24, 1, 72, 0.13);
        padding-top: 31px;
        margin-top: 117px;
        align-items: center;

        @media (max-width: 992px) {
            margin: 31px 25px 0 25px;
        }

        p {
            opacity: 1;
            color: rgba(143,143,143,1);
            font-family: $font-ubuntu;
            font-size: 19px;
            font-weight: 400;
            text-align: left;
            line-height: 32px;
        }
        
        figure {
            
            img {
                width: 102px;
                height: 102px;
            }
        }
        
        div {
            flex-basis: 70%;

            a {
                opacity: 1;
                color: rgba(0,107,241,1);
                font-family: $font-ubuntu;
                font-size: 18px;
                font-weight: 700;
                text-align: left;
                line-height: 32px;
            }
        
        }
    }

}