
// font-size

$font-30 : 30px;
$font-25 : 25px;
$font-22 : 22px;
$font-20 : 20px;
$font-18 : 18px;
$font-16 : 16px;
$font-15 : 15px;
$font-13 : 13px;
$font-11 : 11px;
$font-14 : 14px;

//line height
$line-height17:17px;
$line-height18:18px;
$line-height29:29px;
$line-height22:22px;
$line-height20:20px;
$line-height32:32px;
//color
$color-primary:"";
$color-White:#ffffff;
$color-black: #000000;
$color-Blackcurrant:#0B0022;
$color-Sapphire:#04226B;
$color-Alice-Blue:#EEF7FF;
$color-Whisper:#ECECEC;
$color-Grey:#8B8484;
$color-Nobel:#979797;
$color-Cello:#475059;
$color-Aubergine:#231F20;
$color-Violent-Violet:#180148;
$color-Navy-Blue:#006BF1;
$color-Electric-Purple:#BF06FF;
$color-pink:#FD00FF;
$color-blue:#130E45;
$color-violet:#1C004B;
$color-Solitude:#DFE9F6;
$color-dithered:#BCDFFF;
$color-cyan:#00FFFF;
$color-lightBlue:#0067FF;

// fonts
$font-ubuntu:'Ubuntu', sans-serif;

// font weight
$font-xl:300;
$font-regular:400;
$font-medium:500;
$font-bold:700;

.lumen-report.footer-wave::before{
    
    top: -5px;
}


.graphreport__pie{
    &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #eef7ff; 
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #381474; 
        border-radius: 10px;
      }
}



@mixin commonstyle($fontsize,$fontweight,$textcolor,$lineheight) {
    font-size: $fontsize;
    font-weight: $fontweight;
    color:$textcolor;
    font-family: $font-ubuntu;
    line-height: $lineheight;
}

.lumenreport__topspace{
    padding-top: 2rem;
    @media (min-width:481px) {
        padding-top: 6rem;
    }
}

.smallcontainer{
    
    @media (min-width:1024px) {
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
    }
  p.nodata{
    @media (max-width:480px) {
        padding-bottom: 10rem !important;
    }
  }

}


.hideHeader{
    .lumenheader,
    .mobile-footer-menu{
        display: none !important;
    }
}

.lumenreport__header{
    width: 100%;
    height: 100%;
    min-height: 260px;
    padding-bottom: 2rem;
    background-color:$color-Alice-Blue;

    .lumenreport__headercontent{
        .para{
            font-size: 1.25rem;
            font-weight: 700;
            color:$color-Violent-Violet;
            font-family: $font-ubuntu;
            line-height: 1.2;
           
            max-width: 190px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.5rem;
            text-align: center;
            @media (min-width:768px) {
                font-size: 1.25rem;
                max-width: 100%;
                text-align: left; 
                margin-bottom: 0.5rem;
                margin-left:0px;
            }
        }
        .lumenreport_cards{
            padding: 1.35rem 1.25rem;
            border-radius: 16px 6px 16px 6px;
            box-shadow: 0px 0px 11px rgba(4,34,107, 0.113227);
            opacity: 1;
            background-color: rgba(255,255,255, 1);
            min-height: 102px;
            max-width: 300px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1.4rem;
            @media (min-width:768px) {
                max-width: 100%;
                margin-left: 0;
                margin-bottom: 0rem;
            }
            @media (min-width:768px) and (max-width:992px){
                max-width: 520px;
            }
            @media (min-width:992px) {
                max-width: 675px;
            }
            
            .lumenreport__box{
                @media (min-width:768px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between; 
                }
            }
            .lumenreport__grpname{
                color:$color-Violent-Violet;
                font-family: $font-ubuntu;
                font-size: 1.188rem;
                font-weight: 500;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;
                margin-bottom: 0px;
                line-height: 1.2;
                @media (min-width:768px) {
                    font-size: 1.688rem;
                }
            }
            .textstyle{
                font-size:0.938rem;
                font-weight: 400;
                color:$color-Violent-Violet;
                font-family: $font-ubuntu;
                line-height: 1.1;
                margin-bottom: 0rem;
                @media (min-width:768px) {
                    font-size: 1.125rem;
                }
            }
            .listblock{
                @media (min-width:768px) {
                    display: inline-flex;
                    align-items: center;
                }
            }
            .textstyle--name{
                @extend .textstyle;
                font-weight: 500;
                line-height: 1.125;
                color:$color-violet;
                font-size:0.938rem;
                position: relative;
                margin: .38rem 0px;
                @media (min-width:768px) {
                    font-size: 1rem;
                    margin-left: 2.938rem;
                    
                }
                &:first-child{
                    margin-left: 0px;
                    &::before{
                        content: none;
                    }
                }
                @media (min-width:768px) {
                    &::before{
                        content:'';
                        width: 1px;
                        height: 20px;
                        background-color: #180148;
                        opacity: 0.15;
                        position: absolute;
                        left:-1.469rem; //base on margin left half
                        
                    }
                }
            }
        }
        .lumenreport_btns{
            text-align: center;
            @media (min-width:768px) {
                text-align: left;
            }
        }
        .lumenreport--btn{
            width: 180px;
            height: 50px;
            border-radius: 25px;
            background-color: #1c004b;
            font-size: 1rem;
            font-weight: 700;
            color: #ffffff;
            font-family: $font-ubuntu;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media (min-width:992px) {
                width: 212px;
                height: 50px;
            }
        }
        
    }
}

input::placeholder{
    color:#C4C4C4;
    font-size: 1rem;
    font-weight: 700;
    font-family: $font-ubuntu;
}

.lumenreport__searchBox{
    position: relative;
    .lumenreport--search{
        font-size: 1rem;
        font-family: $font-ubuntu;
        font-weight: 700;
        line-height: 1.125;
        color: $color-Violent-Violet;
        width: 100%;
        display: block;
        padding: 15px 15px 15px 48px;
        max-width: 397px;
        height: 50px;
        border-radius: 10px 3px 10px 3px;
        box-shadow: 0px 0px 6px rgba(4,34,107, 0.113227);
        background-color: rgba(255,255,255, 1);
        border: 0px;
    }
    .lumenreport--searchIcon{
    width: 44px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    padding: 5px 12px;
    }
}

.lumenreport__sticky{
    position: fixed;
    bottom: 47px;
    @media (max-width:767px) {
        bottom: 12px;
    }
    .key--icons{
        width: 23px;
        display: block;
        flex: 1;
        max-width: 23px;
        margin-left: auto;
        margin-right: auto;
        @media (min-width:768px) {
            width: 19px;
            display: block;
            margin-left: 0px;
            margin-right: 10px;
        }
    }
    .lumenreport__key--btn{
        display: flex;
        align-items: center;

        background-color: #EEF7FF;
        width: 62px;
        height: 52px;
        border: 0px;
        border-radius: 0px;
        color:#180148;
        font-size: 0.813rem;
        font-weight: 500;
        font-family: $font-ubuntu;
        letter-spacing: 0.69px;
        @media (min-width:768px) {
            width: 128px;
            height: 43px;
        }
        .text{
            display: none;
            @media (min-width:768px) {
                display: block;
            }
        }
    }
}

.posotion--left{
    left: 0px;
}
.posotion--right{
    right: 0px;
}

.key__fixed{
    position: fixed;
}
.showlist__position{
    bottom: 100px;
    left: 0px; 
    @media (max-width:767px){
        bottom:75px;
    }
}
.guidelist__position{
    bottom: 100px;
    right: 0px; 
    @media (max-width:767px){
        bottom:75px;
    }
}
.showkey__box{
    padding: 16px 8px;
    background-color: #EEF7FF;
    width: 196px;
   
    .showkey__list{
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 32px;
        &:last-child{
            .category--name{
                &::before{
                    content: none;
                }
            }
        }

            .category--icon{
                max-width: 16px;
                width: 16px;
                display: block;
                margin-right: 1.313rem;
                flex: 1;
            }
            .category--name{
                position: relative;
                flex: 1;
                display: block;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: normal;
                color:$color-Violent-Violet;
                font-family: $font-ubuntu;
                
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    width: 100%;
                    height: 1px;
                    background-color: $color-Violent-Violet;
                    opacity: 0.10;
                }
            }
        }
    }
}



.lumenreport__guides{
    background-color:#EEF7FF;
    width:100%;
    padding: 1.5rem;
    overflow: auto;
    @media (max-width:767px) {
        height: 550px;
    }
    @media (min-width:768px) {
        width:804px;
        padding: 2rem;

        height: 580px;
        z-index: 111;
    }

    .lumenreport__content{
        .guide--title{
            font-weight: 700;
            line-height: normal;
            color:$color-Violent-Violet;
            font-family: $font-ubuntu;
            font-size: 1.05rem;
            margin-bottom: 0.5rem;
            @media (min-width:768px) {
                font-size: 1.313rem;
            }
        }
        .guide--text{
            @extend .guide--title;
            font-size: 0.9rem;
            line-height: 1.3;
            font-weight: 500;
            @media (min-width:768px) {
                font-size: 1.125rem;
            }
        }
    }


    .guide__list{
        display: flex;
        flex-wrap: wrap;
        @media (min-width:768px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .guide--item{
           
            width:100%;
            margin-bottom: 0.3rem;
            
            @media (min-width:768px) {
                max-width: 327px;
            }
            .guide__flex{
                align-items: flex-end;
            }
            .guide__block1{
                flex:1;
            }
            .guide--count{
                font-weight: 700;
                line-height: normal;
                font-family: $font-ubuntu;
                font-size: 0.9rem;
                letter-spacing: 0.77px;
                margin-bottom: 0.5rem;
                @media (min-width:768px) {
                    font-size: 1.125rem;
                    letter-spacing: 0.96px;
                }
            } 
            .color--step1{
                color:#381474;
            }
            .color--step2{
                color:#00FFFF;
            }
            .color--step3{
                color:#0067FF;
            }
            .color--step4{
                color:#FF00FF;
            }
            .guide--subtitle{
                @extend .guide--count;
                font-size: 0.788rem;
                letter-spacing: 0.68px;
                // color: $color-Violent-Violet;
                margin-bottom: 0px;
                @media (min-width:768px) {
                    font-size: 1rem;
                    letter-spacing: 0.85px;
                }
            }
            .icons--symbol{
                width: 60px;
                display: block;
                @media (min-width:768px) {
                    width: 70px;
                }
            }

            .guide--text{
                @extend .guide--count;
                font-size: 0.75rem;
                font-weight: 300;
                text-align: left;
                color: $color-Violent-Violet;
                margin-top: 0.8rem;
                margin-bottom: 0.5rem;
                @media (min-width:768px) {
                    font-size: 0.938rem;
                }
            }
        }
    }
    @media (max-width:767px) {
        
        .order1{
            order: 1;
        }
        .order2{
            order: 3;
        }
        .order3{
            order: 2;
        }
        .order4{
            order: 4;
        }
    }

}



// graph 
.graphreport__box{
    margin-top: 1.5rem;
    border-bottom: 3px solid #381474;
    padding-bottom: 1.5rem;
}
.graphreport__inform{
    flex:1;
    max-width: 222px;
    width: 100%;
    &--name{
        color: rgba(255,255,255,1);
        font-family:$font-ubuntu;
        font-size: 1.125rem;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 1.2;
        margin-bottom: 0.2rem;
        @media (min-width:768px) {
            font-size: 1.25rem;
        }
    }
    &--jobtitle{ 
        color: rgba(255,255,255,1);
        font-family:$font-ubuntu;
        font-size: 0.938rem;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 1.3;
        @media (min-width:768px) {
            font-size: 1.125rem;
        }
    }
}

.progresscard__content{
    .progress__chart{
        height: 5px;
        margin-top: 10px;
        background-color: #381474;
        display: flex;
        overflow: hidden;
        line-height: 0;
        font-size: 0.75rem;
        border-radius: 0.25rem;
    }
    .borderRadius3:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}
.attemptCount{
    position: absolute;
}
.graphreport__pie{
    flex:1;
    max-width:770px;
    padding-bottom:30px;
    overflow:auto;
    width: 100%;
    .block{
        margin-left: 48px;
        position:relative;
        &:first-child{
            margin-left: 0px;
        }
        .pie-graph-chart{
            width: 220px;
            height: 220px;
        }
    }
}

.disablebtn{
    pointer-events: none;
    opacity: 0.6;
}

.customePagi{
    
    margin: 1.5rem 0px 0px;
    padding: 0;
    background: transparent;
    color: #fff;
    list-style: none;

    display: flex;
    justify-content: flex-end;
    @media (max-width:480px) {
        padding-bottom: 40px;
    }
    li{
        background-color: transparent;
        border-radius: 0px;
        border:0px;
        &.active {
            .page-link{
                background-color: transparent;
                border: 0px;
                color:#EEF7FF;
                position: relative;
                &::before{
                    content:'';
                    position: absolute;
                    bottom: 0px;
                    width:10px;
                    height: 2px;
                    background-color: #fff;
                }
            }
        }
        &.disabled{
            .page-link{
                background-color: transparent;
                border:0px;
            }
        } 
        .page-link{
            background-color: transparent;
            border-radius: 0px;
            font-size: 1rem;
            font-family: $font-ubuntu;
            font-weight: 400;
            color: #fff;
            border:0px;
            padding: 0.3rem 0.75rem;
        }
    }
}

@media (max-width:767px){
    // html{
    //     overflow: hidden;
    // }
    .position__fixed{
        // position: fixed;
    //     overflow: hidden;
    // position: relative;
    // margin: 0;
    // padding: 0;
    // margin: 0;
    // overflow: hidden;
    // height: 100%;
    // position: relative;

    overflow: hidden;
  margin: 0;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  height: 100vh;
    }
    
}