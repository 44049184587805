
// font-size

$font-30 : 30px;
$font-25 : 25px;
$font-22 : 22px;
$font-20 : 20px;
$font-18 : 18px;
$font-16 : 16px;
$font-15 : 15px;
$font-13 : 13px;
$font-11 : 11px;
$font-14 : 14px;

//line height
$line-height17:17px;
$line-height18:18px;
$line-height29:29px;
$line-height22:22px;
$line-height20:20px;
$line-height32:32px;
//color
$color-primary:"";
$color-White:#ffffff;
$color-black: #000000;
$color-Blackcurrant:#0B0022;
$color-Sapphire:#04226B;
$color-Alice-Blue:#EEF7FF;
$color-Whisper:#ECECEC;
$color-Grey:#8B8484;
$color-Nobel:#979797;
$color-Cello:#475059;
$color-Aubergine:#231F20;
$color-Violent-Violet:#180148;
$color-Navy-Blue:#006BF1;
$color-Electric-Purple:#BF06FF;
$color-pink:#FD00FF;
$color-blue:#130E45;
$color-violet:#1C004B;
$color-Solitude:#DFE9F6;
$color-dithered:#BCDFFF;
$color-cyan:#00FFFF;
$color-lightBlue:#0067FF;

// fonts
$font-ubuntu:'Ubuntu', sans-serif;

// font weight
$font-xl:300;
$font-regular:400;
$font-medium:500;
$font-bold:700;

