.chatbox-container {
    width: auto;
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 12;
    background-color: #fff;
    border-radius: 26px;

    .logo-container {

        img {
            margin-right: 17px;
        }
        
    }

    .close-chat {
        width: 15px;
        height: 15px;
        display: inline-block;
        cursor: pointer;
    } 


    &.content-open {
        min-width: 330px;
        width: 30.277%;
        border-radius: 20px 20px 0 0;
        max-height: 88vh;
        bottom: 2%;
        max-width: 385px;
    }

    figure {
        margin-bottom: 0;
    }

    .chatbox-title {
        margin-bottom: 24px;
        padding: 20px;
        background-color: #180148;
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;

        h3 {
            font-size: 15px;
            margin-bottom: 0;
            color: #fff;
            font-family:  $font-ubuntu !important;
            font-weight: 400;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.6;
        }
    }


    .chat-list {
        overflow-y: scroll;
        position: relative;
        max-height: 70vh;
        padding: 0 7.5% 45px;
        

    }

    ul {
        list-style-type: none;
        padding: 0;

        /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        
            /* Hide scrollbar for IE, Edge and Firefox */
            & {
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
            }

        li.message {
            position: relative;
            margin: 0 auto;
            border-radius: 10px;
            background-color: #fff;
            border: 1px solid #D8D8D8;

            &:not(:first-of-type) {
                margin-top: 17px;
            }

            .title {
                padding: 10px;
                border-bottom: 1px solid #D8D8D8;
            }

            &.select-container {
                margin-bottom: 20px;
            }

            &.response {


                .title {
                    padding: 14px 6% 17px;
                    font-weight: 400;
                    font-size: 16px;
                }

                p {
                    span {
                        display: block;

                        &.user-name {
                            color: #180148;
                            font-weight: bold;
                        }
                    }
                }

                .response-list {
                    padding: 0 6%; 

                    p {
                        padding: 14px 0 17px;
                    }
                }
            }

            p.question {
                padding: 14px 6% 17px;
                word-break: break-word;
                font-family: $font-ubuntu;
                font-weight: 400;
                font-size: 16px;
                color: #505050;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, .1);
                }

                p {
                    padding: 5px 0 !important;
                }

                span {
                        display: block;
                        word-break: break-word;
                    }
            }

            p {
                margin-bottom: 0;
                word-break: break-word;
                font-family: $font-ubuntu;
                font-weight: 500;
                font-size: 15px;
                color: #505050;
            }

            
            .option-container.select-list {
                padding:  6%;
            }

            .option-container:not(.select-list) {
                padding: 0 6%;

                li {
                    padding: 10px 0;
                    position: relative;
                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(0, 0, 0, .1);
                    }

                    &::after {
                        content: "";
                        width: 6px;
                        height: 10px;
                        display: inline-block;
                        background: url("../../Images/chevron_black.svg") no-repeat center/cover;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    
                }
            }

            .css-b62m3t-container {
                width: 96%;
                margin: 10px auto;
            }

            .css-13cymwt-control {
                font-family: $font-ubuntu;
                font-weight: 400;
                border: none;
                outline: none;
            }

            .css-1u9des2-indicatorSeparator {
                display: none;
            }

            .css-13cymwt-control, 
            .css-t3ipsp-control {
                border: none;
                outline: none !important;
                box-shadow: none !important;

                & > div:first-of-type {
                
                    width: 96%;
                    margin: 10px auto !important;
                    border: 0 !important;
                    font-family: $font-ubuntu;
                    font-weight: 400;
            
                    .css-1p3m7a8-multiValue {
                        background-color: transparent;
                        border: 1px solid #180148;
                        border-radius: 50px;
                        padding: 5px 6%;
                    }
                    
                }
            }

            .css-b62m3t-container {
                font-family: $font-ubuntu;
                font-weight: 400;
            }

            .css-t3ipsp-control {
                border: none;
                box-shadow: none;
            }

            
        }
    }

    ul.basic-questions {
        padding: 0 20px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
            flex-basis: 48%;
            padding: 10px;
            background-color: rgba(0,0,0,.1);
            margin-bottom: 20px;
            p {
                margin-bottom: 0;
                text-align: center;

                

            }

            
        }
    }

    .chat-input-content {
        margin: 20px;
        padding: 5px 0;
        width: 90%;
        display: flex;
        justify-content: space-between;
        border: 1px solid #D8D8D8;
        border-radius: 25px;



        input {
            flex-basis: 90%;
            border: none;
            outline: none;
            margin-left: 15px;
        }

        button {
            width: 30px;
            height: 30px;
            display: inline-block;
            background: none;
            border: none;
            font-family: $font-ubuntu;
            font-weight: 400;   

            img {
                width: 100%;
            }
        }
    
    }

    .chat-button {
        background-color: #180148;
        color: #fff;
        border: none;
        padding: 5px 20px;
        border-radius: 50px;
        font-family: $font-ubuntu;
        font-weight: 700;
        min-width: 164px;
        line-height: 1.8;

    }

    .retest-button-container {
        p {
            padding: 0 10px;
            color: #180148;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;
            font-family: $font-ubuntu;
        }
    }

    .logo-center {
        text-align: center;
        margin: 34px auto 34px;
    }

    .chatbox-content {
        position: relative;
        .close-popup {
                position: absolute;
                background-color: rgba(255,255,255,1);
                top: 72px;
                bottom: 0;
                left: 0;
                right: 0;

                .chat-button {
                    margin-bottom: 15px;
                }


                .close-popup-content {
                    width: 75%;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    p:first-child {
                        margin-bottom: 5px;
                    }

                }
                
            }
    }
    

}